import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import {
  styled,
  Box,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import TrackIcon from '@mui/icons-material/MapOutlined';
import CombiIcon from '@mui/icons-material/AirportShuttleOutlined';
import BeginTrackIcon from '@mui/icons-material/FactCheckOutlined';
import DriverIcon from '@mui/icons-material/BadgeOutlined';
import COLORS from '../../Colors';

const buttonTemplate = (color, backgroundColor) => ({
  color,
  textTransform: 'none',
  fontSize: 18,
  transition: 'all 0.4s ease-out',
  '&:hover': {
    backgroundColor,
    transition: 'all 0.4s ease-in',
  },
});

const Typography18 = styled(Typography)(() => ({ fontSize: 18 }));

const StyledDialogTitle = styled(DialogTitle)(() => ({
  width: 'calc(100% - 6rem)',
  display: 'flex',
  alignItems: 'center',
}));

const StyledCancelTrackButton = styled(Button)(() => ({
  ...buttonTemplate(COLORS.RED500, COLORS.RED100),
}));

const StyledAddTrackButton = styled(Button)(() => ({
  ...buttonTemplate(COLORS.SKY600, COLORS.SKY100),
}));

const StyledCancelActionButton = styled(Button)(() => ({
  ...buttonTemplate(COLORS.ZINC500, COLORS.ZINC200),
}));

const panelLabelStyle = {
  display: 'flex',
  alignItems: 'center',
  color: `${COLORS.ZINC500}`,
  fontSize: 16,
  fontWeight: 'bold',
};
const sxBorderBottom = {
  borderBottomWidth: '2px',
  borderBottomStyle: 'solid',
  borderBottomColor: `${COLORS.GRAY300}`,
};

const AlertDialog = ({
  dialogObj: {
    open,
    infoPanel,
    dialogId,
    titleText,
    iconTitle,
    cancelButton,
  },
  addNewTrack,
  confirmCancelTrack,
  handleClose,
}) => {
  const trackId = infoPanel?.trackId ?? 0;
  const track = infoPanel?.track ?? '';
  const unidad = infoPanel?.unidad ?? '';
  const beginTrack = infoPanel?.beginTrack ?? '';
  const driver = infoPanel?.driver ?? '';

  const cancelPanelInfo = (trackId > 0) ? (
    <>
      <Box sx={{ ...panelLabelStyle }}>
        <TrackIcon sx={{ mr: 0.5 }} fontSize="small" />
        Recorrido
      </Box>
      <Typography18 variant="h6" gutterBottom component="div" sx={{ mb: 1 }}>{`${track ?? ''}`}</Typography18>
      <Box sx={{ ...panelLabelStyle }}>
        <CombiIcon sx={{ mr: 0.5 }} fontSize="small" />
        Unidad
      </Box>
      <Typography18 variant="h6" gutterBottom component="div" sx={{ mb: 1 }}>{`${unidad ?? ''}`}</Typography18>
      <Box sx={{ ...panelLabelStyle }}>
        <BeginTrackIcon sx={{ mr: 0.5 }} fontSize="small" />
        Salida
      </Box>
      <Typography18 variant="h6" gutterBottom component="div" sx={{ mb: 1 }}>{`${(new Date(beginTrack)).toLocaleTimeString() ?? ''}`}</Typography18>
      <Box sx={{ ...panelLabelStyle }}>
        <DriverIcon sx={{ mr: 0.5 }} fontSize="small" />
        Conductor
      </Box>
      <Typography18 variant="h6" gutterBottom component="div">{`${driver ?? ''}`}</Typography18>
    </>
  ) : null;
  const cancelTrackButton = (trackId > 0) ? (
    <StyledCancelTrackButton
      color="inherit"
      onClick={() => confirmCancelTrack(trackId)}
      disableElevation
      size="large"
    >
      Cancelar recorrido
    </StyledCancelTrackButton>
  ) : null;
  const msgTrackOver20 = (trackId === 0 && open) ? (
    <Typography18 variant="h6" gutterBottom component="div">
      <p>
        La salida tiene una diferencia mayor a 20 minutos con respecto a la hora
        actual.
      </p>
      <p>
        Se recomienda registrar recorridos con salidas de 20 minutos
        o menos de diferencia.
      </p>
      <p>
        Presiona Registrar para crear la salida de todos modos.
      </p>
    </Typography18>
  ) : null;
  const addTrackOver20Button = (trackId === 0 && open) ? (
    <StyledAddTrackButton onClick={addNewTrack} color="inherit">Registrar</StyledAddTrackButton>
  ) : null;
  const cancelActionsButton = (cancelButton) ? (
    <StyledCancelActionButton onClick={handleClose} color="inherit">Cancelar</StyledCancelActionButton>
  ) : null;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby={`alert-dialog-${dialogId}`}
      fullWidth
      maxWidth="xs"
    >
      <Box sx={{ ...sxBorderBottom, display: 'flex', alignItems: 'center' }}>
        <StyledDialogTitle id={`alert-dialog-${dialogId}`}>
          {iconTitle}
          {titleText}
        </StyledDialogTitle>
        <IconButton aria-label="close" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogContent sx={{ ...sxBorderBottom }}>
        {cancelPanelInfo}
        {msgTrackOver20}
      </DialogContent>
      <DialogActions>
        {cancelTrackButton}
        {addTrackOver20Button}
        {cancelActionsButton}
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;
