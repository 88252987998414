const tiempos = {
  '06:12': '06:46',
  '06:24': '06:58',
  '06:36': '07:10',
  '06:48': '07:22',
  '07:00': '07:34',
  '07:12': '07:46',
  '07:24': '08:00',
  '07:36': '08:15',
  '07:48': '08:30',
  '08:00': '08:45',
  '08:20': '09:00',
  '08:40': '09:20',
  '09:00': '09:40',
  '09:20': '10:00',
  '09:40': '10:20',
  '10:00': '10:40',
  '10:20': '11:00',
  '10:30': '11:20',
  '10:40': '11:40',
  '11:00': '12:00',
  '11:20': '12:20',
  '11:40': '12:40',
  '12:00': '13:00',
  '12:20': '13:20',
  '12:40': '13:40',
  '13:00': '14:00',
  '13:20': '14:20',
  '13:40': '14:40',
  '14:00': '15:00',
  '14:20': '15:20',
  '14:40': '15:40',
  '15:00': '16:00',
  '15:20': '16:20',
  '15:40': '16:40',
  '16:00': '17:00',
  '16:20': '17:20',
  '16:40': '17:40',
  '17:00': '18:00',
  '17:20': '18:20',
  '17:40': '18:40',
  '18:00': '19:00',
  '18:20': '19:20',
  '18:40': '19:40',
  '19:00': '19:55',
  '19:15': '20:10',
  '19:30': '20:25',
  '19:45': '20:40',
  '20:00': '20:55',
  '20:15': '21:10',
};

export default tiempos;
