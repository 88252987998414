import * as React from 'react';
import {
  styled,
  Box,
  Collapse,
  Alert,
  IconButton,
} from '@mui/material';
import SuccessIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorIcon from '@mui/icons-material/ErrorOutline';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/InfoOutlined';

import COLORS from '../../Colors';

const StyledContainer = styled('div')(() => ({
  paddingTop: '0.5rem',
  paddingLeft: '0.675rem',
  paddingRight: '0.675rem',
}));

const BasicAlerts = ({
  stateAlert: {
    open,
    severity,
    message,
  },
  liftingCloseAlert,
}) => {
  const alertGenerator = () => {
    const selectedSeverity = (severity !== 'success' && severity !== 'error') ? 'default' : severity;
    const alertObj = {
      success: { backgroundColor: `${COLORS.GREEN900}`, color: `${COLORS.LIME300}`, icon: (<SuccessIcon sx={{ color: `${COLORS.LIME300}` }} />) },
      error: { backgroundColor: `${COLORS.AMBER900}`, color: `${COLORS.AMBER300}`, icon: (<ErrorIcon sx={{ color: `${COLORS.AMBER300}` }} />) },
      default: { backgroundColor: `${COLORS.SKY900}`, color: `${COLORS.SKY300}`, icon: (<InfoIcon sx={{ color: `${COLORS.SKY300}` }} />) },
    };
    const { backgroundColor, color, icon } = alertObj[selectedSeverity];
    return (
      <Alert
        severity={severity}
        sx={{ backgroundColor, color }}
        icon={icon}
        action={(
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={liftingCloseAlert}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        )}
      >
        {message}
      </Alert>
    );
  };

  return (
    <StyledContainer>
      <Box sx={{ width: '100%' }}>
        <Collapse in={open}>
          {alertGenerator()}
        </Collapse>
      </Box>
    </StyledContainer>
  );
};

export default BasicAlerts;
